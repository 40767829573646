
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import useUser from '@/components/user/user';
import Form from '@/components/form/Form.vue';
import FormItem from '@/components/form/FormItem.vue';
import {ROLE_ADMIN, ROLE_NORMAL} from '@/constants/user';
import LabelButton from '@/components/button/LabelButton.vue';
import {ElMessageBox} from 'element-plus';
import useUserDetail from '@/views/user/detail/userDetail';

export default defineComponent({
  name: 'UserForm',
  components: {
    LabelButton,
    FormItem,
    Form,
  },
  setup() {
    // store
    const ns = 'user';
    const store = useStore();

    const {
      activeId,
    } = useUserDetail();

    const onChangePassword = async () => {
      const {value} = await ElMessageBox.prompt('Please enter the new password', 'Change Password', {
        inputType: 'password',
        inputPlaceholder: 'New password',
        inputValidator: (value: string) => {
          return value?.length < 5 ? 'Invalid password. Length must be no less than 5.' : true;
        }
      });
      return await store.dispatch(`${ns}/changePassword`, {id: activeId.value, password: value});
    };

    const isDetail = computed<boolean>(() => !!activeId.value);

    return {
      ...useUser(store),
      ROLE_ADMIN,
      ROLE_NORMAL,
      onChangePassword,
      isDetail,
    };
  },
});
