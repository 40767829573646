
import {defineComponent, PropType} from 'vue';
import Button, {buttonProps} from '@/components/button/Button.vue';

export default defineComponent({
  name: 'LabelButton',
  components: {Button},
  props: {
    icon: {
      type: [Array, String] as PropType<Icon>,
    },
    label: {
      type: String,
      required: true,
    },
    ...buttonProps
  },
  emits: [
    'click',
  ],
  setup(props: LabelButtonProps, {emit}) {
    return {};
  },
});
